.wineShowArticle {
  width: 90%;
  max-width: 1200px;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  margin: auto;

  &__text {
    width: 85%;
    padding: 3rem 2rem;
    margin: auto;
    font-family: Montserrat;
    line-height: 1.8;
    font-size: clamp(1.3rem, calc(1rem + 0.5rem), 1.8rem);
    color: #2c3e50;
  }

  nav {
    justify-content: center;
    margin-bottom: 2rem;
  }

  nav a {
    font-family: Lora;
    min-width: 12rem;
    text-align: center;
    padding: 1rem 2rem;
    transition: all 0.3s ease;
  }

  .nav-tabs .nav-link {
    background-color: transparent;
    color: #2c3e50;
    font-weight: 600;
    border-radius: 5px;
    margin: 0 0.5rem;
    position: relative;
    overflow: hidden;
  }

  .nav-tabs .nav-link:hover {
    color: $btn-color-purple;
    transform: translateY(-2px);

    &::after {
      width: 80%;
    }
  }

  .nav-tabs .nav-link.active {
    background-color: $color-primary-blue;
    border: 2px solid $color-primary-blue;
    color: $color-primary-yellow;

    &::after {
      width: 90%;
    }
  }

  .nav-tabs .nav-link.disabled {
    color: #a0a5aa;
    background-color: #f5f5f5;
    border: none;
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
  }
}

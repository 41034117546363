//=============================
//HOME WINE SECTION
//=============================

.homeWineSection {
  background-color: $background-color;
  padding: 8rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 95%;
  max-width: 1500px;

  &__container {
    width: 95%;
    max-width: 1200px;
  }

  &__title {
    color: $color-primary-red-dark;
    margin-bottom: 4rem;
    text-align: center;
    width: 100%;

    h1 {
      font-size: clamp(3rem, 5vw, 4.5rem);
      font-weight: 700;
      letter-spacing: 0.02em;

      @include query(max-w-600) {
        font-size: 3.4rem;
        padding: 0 1.5rem;
      }
    }
  }

  &__winesContainer {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;

    @include query(max-w-1024) {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
    }

    @include query(max-w-600) {
      grid-template-columns: 1fr;
      width: 90%;
      gap: 4rem;
    }
  }

  .carousel-item.active {
    display: flex;
  }

  &__carousel {
    margin-top: 4rem;
  }

  &__carouselItem {
    display: flex;

    &__text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include query(max-w-600) {
        display: none;
      }
    }
  }

  &__carouselNextIcon,
  &__carouselPrevIcon {
    width: 3.2rem;
    height: 3.2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.2s ease;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &__carouselNextIcon {
    background-image: url("../assets/icons/right-arrow.png");
  }

  &__carouselPrevIcon {
    background-image: url("../assets/icons/right-arrow.png");
    transform: rotate(180deg);
  }
}

//=============================
//HOME WINE SECTION CARD
//=============================

.homeWineSectionCard {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1.2rem;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  &__border {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__imgContainer {
    width: 100%;
    height: 40rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    height: 100%;
    width: auto;
    object-fit: contain;
    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__title {
    font-family: "Lato", sans-serif;
    font-size: 2.4rem;
    color: $color-primary-blue;
    font-weight: 700;
    margin: 2rem 0 1rem;
    text-align: center;
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.6;
    color: rgba($color-primary-blue, 0.9);
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &__wineRating {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 1rem 0;
  }

  &__button {
    width: 100%;
    background-color: $color-primary-blue;
    color: white;
    padding: 1.2rem 2.4rem;
    border-radius: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    transition: all 0.2s ease;
    text-align: center;
    margin-top: 2rem;

    &:hover {
      background-color: darken($color-primary-blue, 10%);
      transform: translateY(-2px);
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 2rem 2rem;
  }
}

//=============================
//HOME WINE SPOT
//=============================

.wineSpot {
  padding: 6rem 0;

  &__titleContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem 4rem;
  }

  &__title {
    font-size: clamp(3rem, 5vw, 4.5rem);
    color: $color-primary-blue;
    font-weight: 700;
    text-align: right;
    font-family: "Lato", sans-serif;
  }

  &__spotContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    gap: 4rem;
    align-items: center;

    @include query(max-w-1024) {
      flex-direction: column;
      gap: 3rem;
    }
  }

  &__cardImgContainer {
    flex: 1;
    border-radius: 1.2rem;
    overflow: hidden;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  }

  &__cardImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__card {
    flex: 1;
    padding: 2rem;
  }

  &__cardTitle {
    font-size: 3.2rem;
    color: $color-primary-blue;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: right;
  }

  &__cardLocation {
    font-size: 1.8rem;
    color: rgba($color-primary-blue, 0.8);
    text-align: right;
  }
}


//ARTICLE SHOW JUMBO

.articleShowJumbo {
   display: flex;
   flex-direction: column;

   &__container {
      height: 30rem;
      display: flex;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.5);
      padding-top: 2rem;

      @include query(max-w-900){
        height: 10rem;
      }

    }    
    
} 

.wineIndex {
  &__container {
    width: 100%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    flex-direction: row;
  }

  &__rows {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    gap: 2em;
    padding: 1em 4em 3em;
    width: 95%;
    margin: 0 auto;
    width: 100%;

    @include query(max-w-1124) {
      grid-template-columns: repeat(2, minmax(auto, 1fr));
      padding: 1em 2em;
      gap: 1.5em;
      width: 90%;
    }

    @include query(max-w-600) {
      grid-template-columns: minmax(auto, 1fr);
      padding: 2em 1em;
      width: 85%;
    }
  }

  &__sider {
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 250px;
    padding: 2.5em 0 0 3em;
    justify-content: flex-start;
    align-items: flex-start;

    @include query(max-w-1124) {
      display: none;
    }

    .nav-pills .nav-link.active,
    .nav-pills .nav-link.active button {
      background-color: $color-primary-blue;
      color: $color-primary-yellow;
      width: 100%;
      text-align: start;
      border-radius: 8px;
      transition: all 0.3s ease;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    &.sticky {
      position: fixed;
      align-self: flex-start;
      top: 125px;
      width: auto;
    }

    button {
      font-size: 1.5em;
      font-family: Lora, serif;
      font-weight: bold;
      padding: 0.8em 1.2em;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: translateX(5px);
        color: $color-primary-blue;
      }
    }
  }

  &__sidebarButton {
    display: flex;
    justify-content: flex-start;
    background: transparent;
    border: none;

    &:active,
    &:focus {
      border: none;
      border-radius: 8px;
      box-shadow: none;
      outline: none;
      background-color: rgba($color-primary-blue, 0.1);
    }
  }

  &__navItem {
    padding: 0.5rem 1rem;
    width: 100%;

    .nav-link {
      padding: 0;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

//Fonts
$main-font: "Nunito, nunito-fallback-noto, nunito-fallback-georgia, georgia, 'times new roman', times",
  sans-serif;
$title-font: "Lato, lato-fallback-noto, lato-fallback-georgia, georgia, 'times new roman', times",
  sans-serif;

//General Colors
$color-primary-redWine: #051622;
$color-primary-redWine-light: #ce212d;

$color-primary-blue: #051622;
$color-primary-wine: #61082b;
$color-primary-red-dark: #98003c;
$color-primary-yellow: #deb992;

$background-color: #fff;
$background-color-dark: #dfdfdf;

$color-black: #000;
$color-white: #fff;
$color-red: rgb(243, 55, 55);
$color-grey-dark-3: #555;

$color-primary-red: #ff0000;
$text-color: #000;
$text-color-light: #999999;
$text-color-white: #e5e5e5;

$color-social-media: #000;

//buttond colors
$btn-text-color: #e0e6ee;
$btn-color-magenta: rgba(255, 98, 98, 0.8);
$btn-color-purple: rgba(127, 98, 255, 1);
$btn-color-red: rgb(160, 28, 28);
$disabled-color: #6c757d;

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: relative;

  &__spinner {
    position: relative;
    width: 40px;
    height: 40px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 50%;
      animation: pulse 1.8s ease-in-out infinite;
    }

    &::before {
      width: 100%;
      height: 100%;
      background-color: rgba($color-primary-yellow, 0.5);
      animation-delay: -0.5s;
    }

    &::after {
      width: 75%;
      height: 75%;
      background-color: $color-primary-yellow;
      top: 12.5%;
      left: 12.5%;
      animation-delay: 0s;
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0.3;
  }
}

// Modifier for full screen loading
.loading--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
}

// Modifier for contained loading
.loading--contained {
  position: relative;
  width: 100%;
  height: 100%;
}

.wineryIndexCard {
  flex-basis: calc(33.33% - 2rem);
  display: flex;
  flex-direction: column;
  margin: 1rem;
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  @include query(max-w-1300) {
    flex-basis: calc(50% - 2rem);
  }

  @include query(max-w-700) {
    flex-basis: calc(100% - 2rem);
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 0.75rem;
  }

  &__img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.03);
    }
  }

  &__title {
    text-align: center;
    font-size: 1.75rem;
    font-family: Lora, serif;
    font-weight: 600;
    color: $color-primary-blue;
    margin-bottom: 0.5rem;
  }

  &__location {
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    color: rgba($color-primary-blue, 0.8);
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

.wineryShowInfo {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;
  padding: 4em 0;
  max-width: 1200px;
  @include query(max-w-900) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  &__directionsLink {
    color: $color-primary-red-dark;
    text-decoration: none;
    &:hover {
      color: $color-primary-red-dark;
      text-decoration: none;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    min-width: 0;
    flex-grow: 1;
    align-self: flex-start;
    padding: 1em;

    @include query(max-w-900) {
      margin: auto;
      width: 100%;
    }
    @include query(max-w-600) {
      width: 100%;
    }
  }

  &__map {
    display: flex;
    width: 50%;
    min-width: 0;
    flex-grow: 1;
    padding: 1em;
    height: 45em;
    @include query(max-w-900) {
      margin-top: 4rem;
      width: 100%;
    }
  }

  &__sidebarInfo {
    width: 100%;
    padding: 0.5em 1em;
    font-weight: bold;
    text-align: left;
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    font-size: clamp(16px, 2vw, 20px);
    a {
      color: $color-primary-blue;
      text-decoration: none;
      background-clip: text;
      -webkit-background-clip: text;
      transition: all 0.3s ease;

      &:hover {
        text-decoration: underline;
        transform: scale(1.05);
      }

      &:active {
        background-clip: text;
        -webkit-background-clip: text;
      }
    }
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }

    &:last-child {
      border-bottom: none;
    }

    &--schedule {
      display: flex;
      align-items: center;
      gap: 1em;
    }
  }

  &__sidebarTitle {
    width: 50%;
    height: auto;
    font-weight: bold;
    font-size: clamp(14px, 1.8vw, 18px);
  }

  &__sidebarDetails {
    width: 100%;
    height: auto;
    font-weight: 500;
    font-size: clamp(14px, 1.6vw, 16px);
    align-self: center;
  }

  &__sidebarInfo li {
    list-style: none;
  }

  &__sidebarHours {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    margin-top: auto;
    justify-content: space-evenly;
  }

  #wineryShowInfo__sidebarAddress {
    font-size: clamp(0.8em, 1vw, 0.9em);
  }

  ul {
    padding: 0;
  }

  &__isOpen {
    display: inline-flex;
    align-items: center;
    font-size: clamp(14px, 1.6vw, 16px);
    font-weight: 600;
    padding: 0.4em 0.8em;
    border-radius: 20px;
    margin-right: 0.5em;
  }

  &__openText {
    color: #2d8a39;
    background-color: #e6f4e8;
    padding: 0.4em 0.8em;
    border-radius: 20px;

    &::before {
      content: "•";
      display: inline-block;
      margin-right: 0.5em;
      animation: pulse 2s infinite;
    }
  }

  &__closedText {
    color: #c53030;
    background-color: #fde8e8;
    padding: 0.4em 0.8em;
    border-radius: 20px;
  }

  &__hours {
    display: flex;
    margin: auto;
    color: #4a5568;
    font-size: clamp(14px, 1.6vw, 16px);
    font-weight: 500;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
  }

  &__description {
    margin-bottom: 2rem;
    line-height: 1.6;
    font-size: clamp(14px, 1.6vw, 16px);
  }

  &__gridSection {
    padding: 1rem;
    background: #f8f8f8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 1rem;
      font-size: clamp(16px, 2vw, 20px);
      color: #333;
    }

    .location-detail {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      align-items: baseline;

      .label {
        font-weight: 600;
        min-width: 50px;
      }

      .value {
        word-break: break-word;
        font-size: clamp(11px, 1.1vw, 11px);
      }
    }
  }

  &__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__statusWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__statusIndicator {
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: clamp(12px, 1.4vw, 14px);
    font-weight: 500;

    &.open {
      background: #e6f4ea;
      color: #1e7e34;
    }

    &.closed {
      background: #fbe9e7;
      color: #d32f2f;
    }
  }

  &__amenities {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__amenity {
    padding: 0.25rem 0.75rem;
    background: #fff;
    border-radius: 1rem;
    font-size: clamp(12px, 1.4vw, 14px);
    text-transform: capitalize;
  }

  &__amenityIcon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  &__seeHoursButton {
    background: none;
    border: none;
    color: $color-primary-red-dark;
    cursor: pointer;
    font-size: clamp(12px, 1.4vw, 14px);
    padding: 0.25rem 0.5rem;
    text-decoration: underline;

    &:hover {
      color: darken($color-primary-red-dark, 10%);
    }
  }

  &__fullSchedule {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &__fullScheduleContent {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      margin-bottom: 1.5rem;
      text-align: center;
      font-size: 1.25rem;
      color: #2d3748;
    }

    h4 {
      margin: 1.5rem 0 1rem;
      color: $color-primary-red-dark;
      font-size: 1.1rem;
    }
  }

  &__closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    color: #666;

    &:hover {
      color: #333;
    }
  }

  &__scheduleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid #eee;
    font-size: 0.95rem;

    &:last-child {
      border-bottom: none;
    }
  }

  &__day {
    font-weight: 600;
    text-transform: capitalize;
    min-width: 100px;
  }

  &__hours {
    text-align: right;
    color: #4a5568;
  }

  &__hoursSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: auto;
    height: 100%;
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &__modalContent {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
  }

  &__modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h3 {
      margin: 0;
      font-size: 2rem;
      color: $color-primary-redWine;
    }
  }

  &__modalClose {
    background: none;
    border: none;
    font-size: 1.6rem;
    color: $color-primary-redWine;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba($color-primary-redWine, 0.1);
    }
  }

  &__modalBody {
    // Add any specific styling for the modal body
  }
}

.navigationMenu {
  display: none;

  @include query(max-w-600) {
    display: inline;
  }

  &__checkbox {
    display: none;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 200;
    box-shadow: 0 0.8rem 2rem rgba($color-black, 0.08);
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 1rem 2.5rem rgba($color-black, 0.12);
    }
  }

  &__background {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: fixed;
    top: 2.5rem;
    right: 2.5rem;
    background-color: $color-primary-redWine;
    z-index: 100;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    backdrop-filter: blur(5px);
  }

  &__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 150;
    width: 0;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__list {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    padding: 0;
  }

  &__item {
    margin: 1.5rem;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.4s ease;
    transition-delay: calc(0.1s * var(--item-index, 0));
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 2.8rem;
      font-weight: 400;
      padding: 1.2rem 2.4rem;
      color: $color-white;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.1em;
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%
      );
      background-size: 240%;
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 0.8rem;

      @include query(max-w-900) {
        font-size: 2.4rem;
      }
    }

    &:hover,
    &:active {
      background-position: 100%;
      color: $color-white;
      transform: translateX(1rem) scale(1.05);
      box-shadow: 0 0.5rem 1.5rem rgba($color-black, 0.15);
    }

    &--disabled {
      pointer-events: none;
      position: relative;
    }
  }

  &__comingSoon {
    display: block;
    font-size: 0.8em;
    color: rgba($color-primary-yellow, 0.7);
    margin-top: 0.3em;
    font-style: italic;
  }

  //FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    transform: scale(100);
  }

  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;

    .navigationMenu__item {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__icon {
    position: relative;
    margin-top: 2.4rem;
    width: 2.4rem;
    height: 2px;
    background-color: $color-primary-redWine;
    display: inline-block;
    transition: all 0.3s ease;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color-primary-redWine;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      top: -0.8rem;
    }

    &::after {
      top: 0.8rem;
    }
  }

  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}

/* Font loading optimization */
@font-face {
  font-family: "Playfair Display SC";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/playfairdisplaysc/v15/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lb0MEPM2.woff2)
    format("woff2");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrE.woff2)
    format("woff2");
}

// Font variables
$font-primary: "Playfair Display SC", serif;
$font-secondary: "Raleway", sans-serif;

// Font mixins
@mixin font-primary {
  font-family: $font-primary;
  font-display: swap;
}

@mixin font-secondary {
  font-family: $font-secondary;
  font-display: swap;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scrollbar-width: none; /*Remove Scroll bar Firefox*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //Remove scroll bar Chrome
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

*,
*:hover,
*:focus,
*:active {
  outline: 0px none;
}

html {
  // Set up for rem use -  10px/16px = 62.5%
  font-size: 62.5%; //  1 rem = 10px

  @include query(max-w-1200) {
    font-size: 56.25%; // 1rem = 9px - 9/16 = 56.25%
  }

  @include query(max-w-900) {
    font-size: 60%; // 1 rem = 8px - 8/16 = 50%
  }

  @include query(max-w-400) {
    font-size: 50%;
  }

  @include query(max-w-325) {
    font-size: 45%;
  }

  @include query(min-w-1800) {
    font-size: 75%; // 1rem = 12px - 12/16 = 75%
  }
}

body {
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
}

button:focus {
  outline: 0;
}

.spacer {
  height: 12rem;
}

.main {
  height: 100vh;
}

input {
  outline: none;
  text-indent: 10px;

  &::placeholder {
    margin-left: 10px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30x rgb(51, 51, 51) inset !important;
    background-color: transparent;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: rgba($color-primary-redWine, 0.9) !important;
  }
}

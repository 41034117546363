
 
//ARTICLE SHOW NEXT

.articleShowNext {
   height: 30rem;
   padding: 2rem 0;
   margin: auto;
   display: flex;
   flex-direction: row;
   width: 100%;


   &__lg {
      width: 50%;
      margin-right: auto;
      display: flex;
    }
    
    &__sm {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    
    &__sm h1 {
      margin: auto;
    }
    
    &__lg h1 {
      margin: auto;
    }
}

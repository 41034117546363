body {
  font-family: $main-font;
  font-weight: 400;
  color: $text-color;
}

h1 {
  font-weight: 700;
  font-size: 4.8rem;
  text-transform: uppercase;
  font-family: $title-font;
}

h2 {
  font-weight: 700;
  font-size: 3.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-weight: 400;
  font-size: 1.8rem;
}

h5 {
  font-weight: 400;
  font-size: 1.4rem;
}

.tooltip {
  font-size: 1.5rem;
}

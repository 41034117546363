.navigation {
  z-index: 10;
  position: fixed;
  width: 100vw;
  min-height: 80px;
  background-color: rgba(
    5,
    22,
    34,
    0.95
  ); // Slightly transparent for modern look
  backdrop-filter: blur(10px); // Frosted glass effect
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15); // Subtle shadow
  transition: all 0.3s ease; // Smooth transitions
  &__ul li a {
    text-decoration: none;
  }
  &.bg-solid {
    background: $color-primary-blue;
  }

  &.bg-gradient {
    background: linear-gradient(to top, #051622, #61082b);
  }

  &__bottom {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    padding: 0 2rem;
  }

  &__langToggler {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__menu {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include query(max-w-1115) {
      justify-content: flex-start;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    width: 100%;

    @include query(max-w-600) {
      display: none;
    }
  }

  &__ul {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      width: 100%;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -5px;
        left: 50%;
        background-color: #deb992;
        transition: all 0.3s ease;
        transform: translateX(-50%);
      }

      &:hover::after {
        width: 60%;
      }

      a {
        font-size: clamp(1.1rem, 1.5vw, 1.4rem);
        color: #deb992;
        letter-spacing: 3px;
        font-family: $main-font;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0 4rem;
        padding: 0.5rem 1rem;
        transition: all 0.3s ease;

        &:hover {
          text-decoration: none;
          color: lighten(#deb992, 10%);
          transform: translateY(-2px);
        }

        @include query(max-w-1300) {
          margin: 0 2.5rem;
          font-size: clamp(1.1rem, 1.5vw, 1.4rem);
        }
      }
    }
  }

  &__logo {
    max-width: 13.5rem;
    margin: 1.5rem 0;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    @include query(max-w-1115) {
      margin-left: 5rem;
    }

    @include query(max-w-480) {
      max-width: 10rem;
      margin-top: 1.2rem 0;
    }
  }

  transition: background-color 0.3s ease;

  &--scrolled {
    background: linear-gradient(to top, #051622, #61082b);
    backdrop-filter: blur(10px);
  }
}

//=============================
//HOME ABOUT SECTION
//=============================

.homeAboutSection {
  background-color: $background-color;
  padding: 8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  // Add a subtle background pattern
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(
        circle at 25% 25%,
        rgba($color-primary-blue, 0.03) 1%,
        transparent 3%
      ),
      radial-gradient(
        circle at 75% 75%,
        rgba($color-primary-blue, 0.03) 1%,
        transparent 3%
      );
    background-size: 40px 40px;
    opacity: 0.6;
    z-index: 0;
  }

  &__container {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__textContent {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__title {
    font-size: clamp(3rem, 5vw, 4rem);
    color: $color-primary-blue;
    font-weight: 700;
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 1.5rem;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 4px;
      background-color: $color-primary-yellow;
      border-radius: 2px;
    }
  }

  &__description,
  &__mission {
    font-size: 1.8rem;
    line-height: 1.6;
    color: rgba($color-primary-blue, 0.8);
    margin-bottom: 1rem;
    text-align: center;
  }

  &__mission {
    font-style: italic;
    border-left: 4px solid rgba($color-primary-yellow, 0.5);
    padding-left: 2rem;
    max-width: 800px;
    margin: 0 auto 2rem;
  }

  &__subtitle {
    font-size: 2.4rem;
    color: $color-primary-blue;
    font-weight: 700;
    margin: 2rem 0 1.5rem;
    text-align: center;
  }

  &__wineryInfo {
    background-color: rgba(white, 0.7);
    border-radius: 1.2rem;
    padding: 3rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba($color-primary-blue, 0.1);
    margin-top: 2rem;
  }

  &__wineryText {
    font-size: 1.6rem;
    line-height: 1.6;
    color: rgba($color-primary-blue, 0.8);
    margin-bottom: 2rem;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  &__wineryOptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 3rem;

    @include query(max-w-768) {
      grid-template-columns: 1fr;
    }
  }

  &__wineryOption {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    }

    h4 {
      font-size: 1.8rem;
      color: $color-primary-blue;
      font-weight: 700;
      margin-bottom: 1rem;
      text-align: center;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.5;
      color: rgba($color-primary-blue, 0.8);
      text-align: center;
    }
  }

  &__contactCta {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }

  &__contactButton {
    background-color: $color-primary-blue;
    border: none;
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1.2rem 3rem;
    border-radius: 0.8rem;
    transition: all 0.3s ease;
    letter-spacing: 0.05em;

    &:hover {
      background-color: darken($color-primary-blue, 10%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba($color-primary-blue, 0.2);
    }

    &:active {
      transform: translateY(0);
    }
  }

  /* Newsletter Subscription Styles */
  &__newsletter {
    background-color: $color-primary-blue;
    border-radius: 1.2rem;
    padding: 3rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    margin-top: 4rem;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 0;
    }

    .homeAboutSection__subtitle {
      color: white;
      margin-top: 0;
    }
  }

  &__newsletterText {
    font-size: 1.6rem;
    line-height: 1.6;
    color: rgba(white, 0.9);
    margin-bottom: 2rem;
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  &__subscribeForm {
    max-width: 500px;
    margin: 0 auto;
    position: relative;
  }

  &__formGroup {
    display: flex;
    gap: 1rem;

    @include query(max-w-600) {
      flex-direction: column;
    }
  }

  &__emailInput {
    flex: 1;
    font-size: 1.6rem;
    padding: 1.2rem;
    border-radius: 0.8rem;
    border: 1px solid rgba(white, 0.2);
    background-color: rgba(white, 0.9);
    transition: all 0.2s ease;
    margin: auto;

    &:focus {
      border-color: $color-primary-yellow;
      box-shadow: 0 0 0 3px rgba($color-primary-yellow, 0.3);
      background-color: white;
    }

    &::placeholder {
      color: rgba($color-primary-blue, 0.6);
    }
  }

  &__subscribeButton {
    background-color: $color-primary-yellow;
    border: none;
    color: $color-primary-blue;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1.2rem 2.4rem;
    border-radius: 0.8rem;
    transition: all 0.3s ease;
    white-space: nowrap;

    &:hover {
      background-color: darken($color-primary-yellow, 10%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(0);
    }

    &:disabled {
      background-color: rgba($color-primary-yellow, 0.6);
      transform: none;
      box-shadow: none;
    }
  }

  &__alert {
    margin-bottom: 2rem;
    border-radius: 0.8rem;
  }

  &__feedback {
    color: $color-primary-yellow;
    font-size: 1.4rem;
    margin-top: 0.5rem;
    text-align: left;
  }
}

.contact-modal {
  .modal-content {
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    background-color: $color-primary-blue;
    color: white;
    border-bottom: 1px solid #eee;
    padding: 1.5rem;

    .modal-title {
      font-weight: 600;
      color: white;
    }

    .custom-close {
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: transparent;
      border: none;
      opacity: 0.8;
      transition: opacity 0.2s ease, transform 0.2s ease;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-left: auto;

      &:hover {
        opacity: 1;
        transform: scale(1.1);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.25);
      }
    }
  }

  .modal-body {
    padding: 2rem;
  }

  .form-label {
    font-weight: 600;
    color: $color-primary-blue;
    margin-bottom: 0.5rem;
  }

  .form-control {
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    padding: 0.75rem 1rem;
    transition: border-color 0.2s, box-shadow 0.2s;
    font-size: 16px;

    &:focus {
      border-color: #4299e1;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
    }

    &::placeholder {
      color: #a0aec0;
    }
  }

  .text-muted {
    font-size: 0.875rem;
    color: #718096 !important;
  }

  .btn {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s;

    &-primary {
      background-color: $color-primary-blue;
      border-color: $color-primary-blue;

      &:hover {
        background-color: darken($color-primary-blue, 10%);
        border-color: darken($color-primary-blue, 10%);
      }
    }

    &-outline-secondary {
      color: $color-primary-blue;
      border-color: $color-primary-blue;

      &:hover {
        background-color: rgba($color-primary-blue, 0.1);
        color: darken($color-primary-blue, 10%);
      }
    }
  }

  .invalid-feedback {
    font-size: 0.875rem;
    color: #e53e3e;
    margin-top: 0.5rem;
  }

  .text-success {
    color: #28a745 !important;
  }
}

.btn--primary {
   // font-family:'Raleway', sans-serif;
   background-color: $color-primary-redWine;
   color: $btn-text-color;
   outline: none;
   border: none;
   text-transform: uppercase;
   font-weight: 600;
   font-size: 1.8rem;
   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   letter-spacing: 0.25em;
   padding: .5rem 2rem;
   border-radius: .5rem;
}
//ARTICLE SHOW

.articleShow {
  &__mainContainer {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding: 4rem 2rem;
    margin: auto;

    @include query(max-w-1024) {
      padding: 0;
    }
  }

  &__componentContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    padding: 2rem 0;

    @include query(max-w-900) {
      width: 100%;
    }
  }

  &__detailsContainer {
    width: 30%;

    @include query(max-w-1024) {
      width: 70%;
    }
  }
}

.newspaper {
  max-width: 1200px;
  margin: 80px auto 0;
  padding: 20px;
  font-family: "Times New Roman", Times, serif;
  color: #333;
  background-color: #fff;

  // Header
  &__header {
    text-align: center;
    padding: 20px 0;
    margin-bottom: 30px;
  }

  &__header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @include query(max-w-768) {
      flex-direction: column;
      gap: 5px;
    }
  }

  &__date {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include query(max-w-768) {
      order: 2;
    }
  }

  &__name {
    font-size: 40px;
    font-weight: bold;
    font-family: "Old English Text MT", "Times New Roman", serif;

    @include query(max-w-768) {
      order: 1;
      font-size: 32px;
    }

    @include query(max-w-480) {
      font-size: 28px;
    }
  }

  &__edition {
    font-size: 14px;
    font-style: italic;

    @include query(max-w-768) {
      order: 3;
    }
  }

  &__divider {
    height: 3px;
    background-color: #000;
    margin: 10px 0;

    &:after {
      content: "";
      display: block;
      height: 1px;
      background-color: #000;
      margin-top: 2px;
    }
  }

  // Content
  &__content {
    max-width: 900px;
    margin: 0 auto;
  }

  &__title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 15px;
    font-family: "Georgia", serif;
    text-align: center;

    @include query(max-w-768) {
      font-size: 30px;
    }

    @include query(max-w-480) {
      font-size: 26px;
    }
  }

  &__byline {
    font-size: 16px;
    margin-bottom: 20px;
    font-style: italic;
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }

  &__author {
    font-weight: bold;
    margin-right: 10px;
  }

  &__category {
    text-transform: uppercase;
    font-size: 12px;
    background-color: #f5f5f5;
    padding: 3px 8px;
    border-radius: 3px;
  }

  // Article Layout
  &__article-layout {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 30px;
    margin-top: 30px;

    @include query(max-w-768) {
      grid-template-columns: 1fr;
    }
  }

  // Main Column
  &__main-column {
    border-right: 1px solid #ddd;
    padding-right: 30px;

    @include query(max-w-768) {
      border-right: none;
      padding-right: 0;
      border-bottom: 1px solid #ddd;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }

  &__lead-paragraph {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 25px;

    &:first-letter {
      font-size: 28px;
      font-weight: bold;
      float: left;
      padding-right: 8px;
      line-height: 1;
    }
  }

  &__main-image {
    margin-bottom: 20px;
  }

  &__image {
    width: 100%;
    height: auto;
    display: block;
  }

  &__image-caption {
    font-size: 13px;
    font-style: italic;
    color: #666;
    margin-top: 8px;
    text-align: center;
    padding: 0 10px;
  }

  // Text Content
  &__text-content {
    font-size: 16px;
    line-height: 1.7;
  }

  &__paragraph {
    margin-bottom: 25px;

    p {
      margin-bottom: 15px;
      text-align: justify;
      hyphens: auto;
    }
  }

  &__subheading {
    font-size: 22px;
    font-weight: bold;
    margin: 30px 0 15px;
    font-family: "Georgia", serif;
  }

  &__inline-image {
    margin: 20px 0;
  }

  // Secondary Column
  &__secondary-column {
    @include query(max-w-768) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    @include query(max-w-480) {
      grid-template-columns: 1fr;
    }
  }

  // Related Content
  &__related-content {
    background-color: #f9f9f9;
    padding: 20px;
    margin-top: 30px;
    border: 1px solid #eee;

    @include query(max-w-768) {
      margin-top: 0;
    }
  }

  &__related-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ddd;
  }

  &__related-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;
      padding-left: 15px;
      position: relative;

      &:before {
        content: "•";
        position: absolute;
        left: 0;
        color: #999;
      }
    }
  }

  &__related-link {
    color: #333;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // Article Footer
  &__article-footer {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
  }

  &__metadata {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;

    @include query(max-w-480) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__published-date {
    font-style: italic;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__tag {
    background-color: #f0f0f0;
    padding: 3px 8px;
    border-radius: 3px;
    font-size: 12px;
  }
}

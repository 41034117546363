.wineSearch {
  width: 100%;
  position: relative;
  margin: 80px 0 0 0;
  display: flex;
  min-height: 45vh;
  background-image: url("../assets/imgs/winesSearch.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &__bg {
    position: absolute;
    inset: 0;
    background-color: rgba($color-primary-blue, 0.7);
  }

  &__inputFields {
    position: relative;
    width: 90%;
    max-width: 800px;
    margin: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    button {
      padding: 0.8em 3em;
      background-color: $color-primary-red;
      border: none;
      border-radius: 4px;
      font-size: clamp(1.2rem, 1.6vw, 1.9rem);
      font-weight: 600;
      color: white;
      transition: all 0.2s ease-in-out;

      &:active,
      &:hover,
      &:focus {
        background-color: darken($color-primary-red, 10%);
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }

  label {
    font-size: clamp(1.1rem, 1.5vw, 1.8rem);
    font-weight: 500;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  input[type="text"] {
    width: 100%;
    max-width: 600px;
    height: 45px;
    padding: 0 1rem;
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    font-weight: 400;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: all 0.2s ease;

    &::placeholder {
      font-weight: 300;
      font-size: clamp(1.2rem, 1.6vw, 1.9rem);
    }

    &:focus {
      outline: none;
      border-color: $color-primary-red;
      box-shadow: 0 0 0 2px rgba($color-primary-red, 0.2);
    }
  }

  @media (max-height: 600px) {
    min-height: 60vh;
  }
}

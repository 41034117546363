//=============================
//HOME SUBSCRIBE 
//=============================

.homeSubscribe {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
 
   padding: 2em 1em 2.5em 1em;
   background-image: url("../assets/Home/subscribe.png");   
   height: 23em;
 
   &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
   }
 
   h1{
    color: $color-primary-redWine;
    font-weight: 400;
 
    @include query(max-w-1024) {
     text-align: center;
    }
 
    @include query(max-w-775){
       font-size: 4.2rem;
    }
 
    @include query(max-w-600){
      font-size: 3.2rem;
    }
 
   }
 
   &__inputContainer {
     width: 100%;
 
     @include query(max-w-775) {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center; 
     }
 
     input {
      height: 3.8rem;
      width: 79%;
      outline: none;
      border-radius: 1rem;
      margin: 1rem 1rem 1rem 0; 
     }
   }
 
   &__button {
     height: 3.8rem;
     border-radius: 1rem;
   }
 
   &__subText {
     font-weight: 300;
 
     @include query(max-w-775) {
       text-align: center;
     }
   }
 }
.articleIndex {
  margin-top: 80px;
  &__container {
    width: 100%;
    margin-left: auto;
    padding-top: 1em;
    display: flex;
    flex-direction: row;
  }

  &__rows {
    display: flex;
    flex-direction: column;
    padding: 1em 3.5em 2em 3.5em;
    width: 85%;
    margin: auto;

    @include query(max-w-1124) {
      width: 100%;
      padding: 1em 0;
    }
  }

  &__sider {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding-left: 5em;
    padding-top: 2.5em;
    justify-content: flex-start;
    align-items: flex-start;
    @include query(max-w-1124) {
      display: none;
    }
    .nav-pills .nav-link.active,
    .nav-pills .nav-link.active button {
      background-color: $color-primary-blue;
      color: $color-primary-yellow;
      width: 100%;
      text-align: start;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    &.sticky {
      position: fixed;
      align-self: flex-start;
      top: 125px;
      width: auto;
    }
  }

  &__sidebar button {
    font-size: 1.5em;
    font-family: Lora;
    font-weight: bold;
  }

  &__sidebar &__sidebarButton {
    display: flex;
    justify-content: flex-start;
  }

  &__sidebar &__sidebarButton:active,
  &__sidebar &__sidebarButton:focus {
    border: none;
    border-radius: 0;
    transition: none;
    box-shadow: none;
    outline: none;
  }
}

// Newspaper Front Page Styling
.newspaper-frontpage {
  max-width: 1200px;
  margin: 80px auto 0;
  padding: 20px;
  font-family: "Times New Roman", Times, serif;
  color: #333;
  background-color: #fff;

  // Masthead
  &__masthead {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #000;
  }

  &__date {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 60px;
    font-weight: bold;
    font-family: "Old English Text MT", "Times New Roman", serif;
    margin: 0;
    line-height: 1;

    @include query(max-w-768) {
      font-size: 40px;
    }

    @include query(max-w-480) {
      font-size: 32px;
    }
  }

  &__edition {
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
  }

  &__divider {
    height: 3px;
    background-color: #000;
    margin: 10px 0;

    &:after {
      content: "";
      display: block;
      height: 1px;
      background-color: #000;
      margin-top: 2px;
    }
  }

  // Navigation
  &__nav {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  &__nav-item {
    background: none;
    border: none;
    font-family: "Times New Roman", Times, serif;
    font-size: 16px;
    padding: 5px 15px;
    margin: 0 5px;
    cursor: pointer;
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      font-weight: bold;

      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }

  // Content Layout
  &__content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px;

    @include query(max-w-768) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include query(max-w-480) {
      grid-template-columns: 1fr;
    }
  }

  &__no-articles {
    grid-column: 1 / -1;
    text-align: center;
    padding: 40px;
    font-size: 18px;
    font-style: italic;
  }

  // Main Article
  &__main-article {
    grid-column: 1 / 5;
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;

    @include query(max-w-768) {
      grid-column: 1 / -1;
    }
  }

  &__headline {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 15px;
    font-family: "Georgia", serif;

    @include query(max-w-768) {
      font-size: 28px;
    }
  }

  &__main-article-content {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @include query(max-w-768) {
      flex-direction: column;
    }
  }

  &__main-image {
    flex: 0 0 60%;

    @include query(max-w-768) {
      flex: 0 0 100%;
    }
  }

  &__main-text {
    flex: 1;
  }

  &__image {
    width: 100%;
    height: auto;
    display: block;
  }

  &__byline {
    font-size: 14px;
    margin-bottom: 10px;
    font-style: italic;
    display: flex;
    justify-content: space-between;
  }

  &__author {
    font-weight: bold;
  }

  &__category {
    text-transform: uppercase;
    font-size: 12px;
  }

  &__lead {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;

    &:first-letter {
      font-size: 24px;
      font-weight: bold;
      float: left;
      padding-right: 6px;
      line-height: 1;
    }
  }

  &__continue {
    font-style: italic;
    font-size: 14px;
    display: inline-block;

    &:after {
      content: " →";
    }
  }

  // Secondary Articles
  &__secondary-articles {
    grid-column: 5 / 7;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include query(max-w-768) {
      grid-column: 1 / -1;
    }
  }

  &__secondary-article {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }

  &__secondary-headline {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 10px;
    font-family: "Georgia", serif;
  }

  &__secondary-image {
    margin-bottom: 10px;
  }

  &__summary {
    font-size: 14px;
    line-height: 1.5;
  }

  // Column Articles
  &__columns {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    border-top: 3px solid #000;
    border-bottom: 1px solid #ddd;
    padding: 20px 0;

    @include query(max-w-768) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include query(max-w-480) {
      grid-template-columns: 1fr;
    }
  }

  &__column {
    padding: 0 10px;
    border-right: 1px solid #ddd;

    &:last-child {
      border-right: none;
    }

    @include query(max-w-768) {
      &:nth-child(2n) {
        border-right: none;
      }
    }

    @include query(max-w-480) {
      border-right: none;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__column-headline {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 10px;
    font-family: "Georgia", serif;
  }

  &__column-content {
    display: flex;
    flex-direction: column;
  }

  &__column-image {
    margin-bottom: 10px;
  }

  &__byline-small {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 5px;
  }

  &__brief {
    font-size: 14px;
    line-height: 1.4;
  }

  // Bottom Articles
  &__bottom-articles {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include query(max-w-768) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include query(max-w-480) {
      grid-template-columns: 1fr;
    }
  }

  &__bottom-article {
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #eee;
  }

  &__bottom-headline {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 8px;
    font-family: "Georgia", serif;
  }

  &__date-small {
    font-size: 11px;
  }

  &__category-small {
    font-size: 10px;
    text-transform: uppercase;
    background-color: #eee;
    padding: 2px 5px;
    margin-left: 5px;
  }

  &__snippet {
    font-size: 13px;
    line-height: 1.4;
    margin-top: 5px;
  }

  // Article Links
  &__article-link {
    color: inherit;
    text-decoration: none;
    display: block;

    &:hover {
      h2,
      h3,
      h4,
      h5 {
        text-decoration: underline;
      }
    }
  }

  // Footer
  &__footer {
    border-top: 3px double #000;
    padding-top: 20px;
    margin-top: 40px;
  }

  &__footer-content {
    display: flex;
    justify-content: space-between;

    @include query(max-w-480) {
      flex-direction: column;
    }
  }

  &__footer-section {
    flex: 0 0 48%;

    @include query(max-w-480) {
      margin-bottom: 20px;
    }
  }

  &__footer-heading {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__footer-links {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 8px;
    }
  }

  &__footer-link {
    color: #333;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__footer-button {
    background: none;
    border: none;
    color: #333;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wineryShowArticle {
  width: 70%;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @include query(max-w-900) {
    width: 90%;
    padding: 1.5rem;
  }

  &__text {
    width: 100%;
    padding: 2rem 1.5rem;
    margin: 1rem auto;
    font-family: Montserrat;
    line-height: 1.8;
    color: #444;
    font-size: 1.1rem;
  }

  nav {
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @include query(max-w-600) {
      flex-wrap: wrap;
    }
  }

  nav a {
    font-family: Lora;
    min-width: 120px;
    padding: 1rem 1.5rem;
    text-align: center;
    transition: all 0.2s ease;
  }

  .nav-tabs .nav-link {
    background-color: transparent;
    border: none;
    color: #666;
    font-weight: 600;
    position: relative;
    transition: all 0.2s ease;
  }

  .nav-tabs .nav-link:hover {
    color: $btn-color-purple;
    transform: translateY(-1px);
  }

  .nav-tabs .nav-link.active {
    background-color: transparent;
    border: none;
    color: $color-primary-red;
    font-weight: 700;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color-primary-red;
      transition: all 0.2s ease;
    }
  }

  .nav-tabs .nav-link.disabled {
    color: $disabled-color;
    background-color: transparent;
    border: none;
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.addWineryScreen {
   display: flex;
   height:100%;
   width: 100%;   
   background-image: url("https://images.unsplash.com/photo-1563514227147-6d2ff665a6a0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80");
   background-size: cover;

   &__left {
      width: 20%;
      border-right: 1px solid $color-primary-redWine;
   }

   &__right {
      width:80%;

   }


   &__formContainer {
      padding: 1rem;
      opacity: 0.9;
      min-height: 83vh;
      height: 100%;
      width: 100%;
      background-color: $background-color-dark;

      &-buttonsContainer{
         // position: fixed;
         padding: 1rem;
         background-color:$background-color-dark;
         bottom: 1rem;
         text-align: center;
         width: 100%;

         button {
            margin: 1rem;
         }
      }
   }

}


.wineryShowImages {
  width: 100%;
  max-width: 1200px;
  margin: 6rem auto 2rem;
  padding: 0 1rem;

  .my-gallery {
    .image-gallery-image {
      object-fit: cover;
    }
    .image-gallery-thumbnail-image {
      object-fit: cover;
    }
  }
}

.breadCrums {
   display: flex;
   position: relative;
   top: 0;
   left: 0;

   &__step {
         padding-right: 1rem;
         font-size: 1.5rem;
         list-style: none;
         transition: all 0.5s ease;

         &:hover{
            cursor: pointer;
            color: $color-primary-redWine;
         }
   

      &--active {
         color: $text-color;

         &:hover{
            color: $color-primary-redWine;
         }
      }

     &--disabled{
         color: $text-color-light;

         &:hover{
            color: $color-primary-redWine;
         }
      }
   }
}
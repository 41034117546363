//=======================
//WINE SHOW INFO
//=======================
.wineShowInfo {
  display: flex;
  flex-direction: row;
  margin: 2rem auto;
  justify-content: space-between;
  width: 95%;
  max-width: 1200px;
  align-items: flex-start;
  padding: 2rem;
  @include query(max-w-900) {
    flex-direction: column;
    align-items: center;
    width: 95%;
    padding: 1.5rem;
  }

  &__sidebarContainer {
    display: flex;
    flex-direction: column;
    width: 45%;
    background: white;
    padding: 2rem;
    height: 100%;

    @include query(max-w-900) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  // Override default react-image-gallery styles

  &__image {
    width: 50%;
    padding: 1rem;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;

    @include query(max-w-900) {
      width: 100%;
      padding: 0;
    }

    .toggle-view-btn {
      position: absolute;
      bottom: 150px;
      left: 80%;
      transform: translateX(-50%);
      padding: 10px 20px;
      background: rgba(255, 255, 255, 0.9);
      border: none;
      border-radius: 20px;
      cursor: pointer;
      font-weight: 500;
      font-size: clamp(0.875rem, 2vw, 1rem);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
      z-index: 10;

      &:hover {
        background: white;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      }
    }

    .map-marker {
      width: 20px;
      height: 20px;
      background: #e74c3c;
      border-radius: 50%;
      border: 2px solid white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    //   .image-gallery {
    //     background-color: #f8f8f8;
    //     border-radius: 8px;
    //     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    //     overflow: hidden;
    //     width: 100%;

    //     &-content {
    //       flex: 1;
    //       position: relative;
    //       min-height: 0;
    //     }

    //     &-slide {
    //       img {
    //         width: 100%;
    //         height: 400px;
    //         object-fit: contain;
    //       }
    //     }

    //     // Navigation arrows
    //     &-left-nav,
    //     &-right-nav {
    //       padding: 0 1rem;
    //       font-size: clamp(1.5rem, 4vw, 2.5rem);
    //       color: white;
    //       text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    //       opacity: 0.7;
    //       transition: all 0.3s ease;

    //       &:hover {
    //         color: white;
    //         opacity: 1;
    //       }
    //     }

    //     // Thumbnails
    //     &-thumbnails-wrapper {
    //       padding: 10px 0;
    //       background: white;
    //     }

    //     &-thumbnails-container {
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }

    //     &-thumbnail {
    //       width: 80px;
    //       height: 60px;
    //       margin: 0 5px;
    //       border: 3px solid transparent;
    //       transition: all 0.3s ease;

    //       &.active {
    //         border-color: #007bff;
    //       }

    //       &:hover {
    //         border-color: #0056b3;
    //       }

    //       img {
    //         width: 100%;
    //         height: 100%;
    //         object-fit: contain;
    //       }
    //     }

    //     // Fullscreen button
    //     &-fullscreen-button {
    //       padding: 0.5rem;
    //       color: white;
    //       text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    //       opacity: 0.7;
    //       transition: all 0.3s ease;
    //       z-index: 10;

    //       &:hover {
    //         opacity: 1;
    //       }
    //     }
    //   }
  }

  &__sidebarInfo {
    width: 100%;
    padding: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__sidebarTitle {
    width: 50%;
    height: auto;
    font-weight: bold;
    font-size: clamp(14px, 1.8vw, 18px);
  }

  &__sidebarDetails {
    width: 65%;
    font-weight: 500;
    color: #333;
    font-size: clamp(1rem, 2vw, 1.2rem);

    span {
      display: inline-block;
      margin-right: 0.5rem;
    }

    @include query(max-w-900) {
      text-align: right;
    }
  }

  &__sidebarInfo li {
    list-style: none;
  }
}

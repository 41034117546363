.burgerMenu {
  .bm-burger-button {
    position: fixed;
    width: 3.6rem;
    height: 3rem;
    left: 2rem;
    top: 75px;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  li a {
    color: #fff;
    font-size: 2rem;
    padding: 1.5rem 2rem;
    display: block;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: translateX(5px);
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $color-primary-red-dark;
    border-radius: 2px;
    transition: background 0.2s ease;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: darken($color-primary-red-dark, 10%);
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 5rem !important;
    width: 5rem !important;
    top: 2rem !important;
    right: 2rem !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #fff;
    width: 0.3rem !important;
    height: 2.5rem !important;
  }

  /*
   Sidebar wrapper styles
   Note: Beware of modifying this element as it can break the animations
   */
  .bm-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80vw;
    max-width: 60rem;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.15);
  }

  /* General sidebar styles */
  .bm-menu {
    background: $color-primary-red-dark;
    padding: 2.5rem 0;

    p {
      color: #fff;
      margin: 0;
      padding: 1rem 2rem;
      font-size: 1.6rem;
    }
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: $color-primary-red-dark;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #fff;
    padding: 2rem 0;
  }

  /* Individual item */
  .bm-item {
    display: block;
    outline: none;
    margin-bottom: 1rem;

    &:focus {
      outline: none;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    height: 0 !important;
    background: rgba(0, 0, 0, 0.3) !important;
  }
}

@media screen and (min-width: 1177px) {
  .burgerMenu {
    display: none;
  }
}

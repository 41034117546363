.wineryShowDatos {
  display: flex;
  flex-direction: row-reverse;
  margin: auto;
  // justify-content: space-between;
  width: 95%;
  align-items: self-start;
  padding: 4em 0;
  max-width: 1200px;
  @include query(max-w-900) {
    flex-direction: column;
  }

  &__storyContainer {
    display: flex;
    width: 100%;
    max-width: 50%;
    padding-right: 2rem;

    &--noGallery {
      max-width: 100%;
    }

    @include query(max-w-900) {
      max-width: 100%;
      padding-right: 0;
      margin-bottom: 2rem;
    }
  }

  &__story {
    width: 100%;
    padding: 2rem;
  }

  &__storyTitle {
    font-size: clamp(2rem, calc(1.7rem + 1.4vw), 2.8rem);
    margin-bottom: 1rem;
    color: #333;
  }

  &__storyText {
    font-size: clamp(1.4rem, calc(1.2rem + 0.8vw), 1.6rem);
    line-height: 1.6;
    color: #666;
  }

  &__details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    font-size: clamp(1rem, calc(0.9rem + 0.5vw), 1.2rem);

    @include query(max-w-900) {
      justify-content: center;
    }
  }

  &__phone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 255, 0.05);
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-2px);
      background-color: rgba(0, 0, 255, 0.1);
    }

    @include query(max-w-900) {
      width: 100%;
      max-width: 250px;
    }
  }

  &__email {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgba(0, 128, 0, 0.05);
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-2px);
      background-color: rgba(0, 128, 0, 0.1);
    }

    @include query(max-w-900) {
      width: 100%;
      max-width: 250px;
    }
  }

  &__galleryContainer {
    width: 50%;
    min-width: 0;
    padding: 1rem;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;

    @include query(max-w-900) {
      width: 100%;
      padding: 0;
    }

    .image-gallery {
      background-color: #f8f8f8;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      width: 100%;

      &-contain {
        img {
          object-fit: contain;
        }
      }

      &-cover {
        img {
          object-fit: cover;
        }
      }

      &-content {
        flex: 1;
        position: relative;
        min-height: 0;
      }

      &-slide {
        height: auto;
        img {
          width: 100%;
          height: 400px;
        }
      }

      // Navigation arrows
      &-left-nav,
      &-right-nav {
        padding: 0 1rem;
        font-size: clamp(1.5rem, 4vw, 2.5rem);
        color: white;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        opacity: 0.7;
        transition: all 0.3s ease;

        &:hover {
          color: white;
          opacity: 1;
        }
      }

      // Thumbnails
      &-thumbnails-wrapper {
        padding: 10px 0;
        background: white;
      }

      &-thumbnails-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-thumbnail {
        width: auto;
        max-height: 60px;
        margin: 0 5px;
        border: 3px solid transparent;
        transition: all 0.3s ease;

        &.active {
          border-color: #007bff;
        }

        &:hover {
          border-color: #0056b3;
        }

        img {
          width: 100%;
          height: 100%;
          max-height: 50px;
          object-fit: contain;
        }
      }

      // Fullscreen button
      &-fullscreen-button {
        padding: 0.5rem;
        color: white;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        opacity: 0.7;
        transition: all 0.3s ease;
        z-index: 10;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

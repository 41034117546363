//=============================
//HOME ARTICLE SECTION
//=============================

.homeArticleSection {
  background-color: $background-color;
  padding: 8rem 0 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;

  &__title {
    color: $color-primary-red-dark;
    margin-bottom: 4rem;
    text-align: center;

    h1 {
      font-size: clamp(3rem, 5vw, 4.5rem);
      font-weight: 700;
      letter-spacing: 0.02em;

      @include query(max-w-600) {
        font-size: 3.4rem;
      }
    }
  }

  @include query(max-w-1024) {
    padding: 6rem 0 3rem 0;
  }

  &__cardContainer {
    width: 95%;
    padding: 2rem 0;
  }
}

.badge {
  background-color: $color-primary-blue;
  color: $color-primary-yellow;
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  margin: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

//=============================
//HOME ARTICLE SECTION CARD
//=============================

.homeArticleSectionCard {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1.2rem;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 1.5rem;

  &__title {
    font-family: "Lato", sans-serif;
    font-size: 2.4rem;
    color: $color-primary-blue;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include query(max-w-600) {
      font-size: 2rem;
    }
  }

  &__imageContainer {
    position: relative;
    border-radius: 1.2rem;
    overflow: hidden;
    margin-bottom: 2rem;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      max-height: 450px;
      object-fit: cover;
      transition: transform 0.5s ease;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      @include query(max-w-600) {
        max-height: 350px;
      }
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__authorSection {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    color: rgba($color-primary-red-dark, 0.9);
    font-weight: 500;

    @include query(max-w-600) {
      font-size: 1.4rem;
    }
  }

  &__cardText {
    font-size: 1.6rem;
    line-height: 1.6;
    color: rgba($color-primary-blue, 0.9);
    margin-bottom: 1.5rem;

    @include query(max-w-600) {
      font-size: 1.5rem;
    }
  }

  &__buttonContainer {
    margin-top: auto;
  }

  &__button {
    display: inline-block;
    background-color: $color-primary-blue;
    color: white;
    padding: 1.2rem 2.4rem;
    border-radius: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    transition: all 0.2s ease;
    text-align: center;

    &:hover {
      background-color: darken($color-primary-blue, 10%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    &:active {
      transform: translateY(0);
    }
  }
}

.winesIndexCard {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @include query(max-w-1024) {
    width: 100%;
    padding: 1.25rem;
  }

  &__title {
    font-family: "Lora", serif;
    font-size: 1.75rem;
    color: $color-primary-blue;
    font-weight: 600;
    margin-bottom: 0.75rem;
    text-align: center;

    @include query(max-w-1024) {
      font-size: 1.5rem;
    }
  }

  &__text {
    font-size: 1.25rem;
    font-weight: 400;
    color: rgba($color-primary-blue, 0.9);
    margin: 0 0 1rem 0;
    line-height: 1.5;
  }

  &__imgContainer {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      height: 280px;
      object-fit: contain;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.03);
      }

      @include query(max-w-600) {
        height: 220px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem;
  }

  &__info {
    font-size: 1.25rem;
    font-weight: 400;
    color: rgba($color-primary-blue, 0.8);
    line-height: 1.4;
  }

  &__border {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    padding-top: 1rem;

    a {
      width: 100%;
      max-width: 200px;
    }
  }

  &__button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    margin-top: 1rem;
    background-color: $color-primary-blue;
    color: white;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    border-radius: 6px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: darken($color-primary-blue, 10%);
      transform: translateY(-2px);
    }
  }
}

.loginRegister {
   min-height: 83.5vh;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: url("https://images.unsplash.com/photo-1563514227147-6d2ff665a6a0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80");
   background-size: cover;
   text-align: center;

   h1 {
      color: $color-primary-redWine;
      font-size: 4rem;
   }


   &__container {
      width: 70%;
      min-height: 83.5vh;
      height: 100%;
      background-color: $background-color;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      opacity: 0.9;

      h1 {
         color: $color-primary-redWine;
         font-size: 4rem;
         margin: 3rem;
      }

      h4 {
         font-size: 2rem;
         padding: 1rem;
      }

      p{
         font-size: 1.5rem;
      }
   }

   &__inputs {
      width: 60%
   }

   &__input {
      width: 100%;
      font-size: 2rem;
      padding: .55em;
      width: 100%;
      margin-bottom: 1em;
      border: none;
      border-bottom: 1px solid rgba($color-primary-redWine, 0.8);
      outline: none;
      box-shadow: none;

      &::placeholder {
         color: #4d4d4d;
         font-size: 1.5rem;
         padding: 0;
         transition: all 0.5s ease;
      }
   }


    &__button {
      margin: 5rem;
   }


}



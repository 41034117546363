.wineryShowAmenities {
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &__container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
  }

  &__card {
    width: 8rem;
    text-align: center;
    padding: 1.5rem;
    border-radius: 0.8rem;
    background-color: rgba(0, 0, 0, 0.03);
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-2px);
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  &__card i {
    color: #444;
    font-size: 2.5rem;
    margin-bottom: 0.8rem;
    display: block;
    transition: all 0.2s ease;
  }

  &__label {
    font-size: 1.2rem;
    color: #666;
    margin-top: 0.5rem;
    font-weight: 500;
  }

  @include query(max-w-900) {
    width: 90%;
    padding: 1.5rem;

    &__card {
      width: 7rem;
      padding: 1rem;
    }

    &__card i {
      font-size: 2rem;
    }

    &__label {
      font-size: 1rem;
    }
  }
}

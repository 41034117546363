.footer {
  color: $color-primary-yellow;
  background-color: $color-primary-redWine;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #051622;
  &__container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
  }

  &__brand {
    h3 {
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.6rem;
      opacity: 0.9;
    }
  }

  &__links {
    display: flex;
    gap: 4rem;
  }

  &__about,
  &__contact {
    min-width: 200px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  &__social {
    display: flex;
    gap: 1.5rem;

    &-icon {
      font-size: 2.4rem;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;
  }

  &__website {
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &__divider {
    opacity: 0.6;
  }

  &__copyright {
    opacity: 0.8;
  }
}

.contact {
  &__link {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: inherit;
    text-align: left;
    font-family: inherit;

    h3 {
      margin: 0;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid currentColor;
      outline-offset: 2px;
    }
  }
}

.aboutUs,
.contact {
  h3 {
    color: $color-primary-yellow;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 3rem 1.5rem;

    &__main {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__links {
      gap: 2rem;
      align-items: center;
    }

    &__bottom {
      flex-direction: column;
      gap: 1.5rem;
    }

    &__info {
      flex-direction: column;
      gap: 0.5rem;
    }

    &__divider {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .footer {
    &__social-icon {
      font-size: 2rem;
    }

    &__brand {
      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 1.4rem;
      }
    }
  }
}

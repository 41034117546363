.wineShowJumbo {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 80px;

  &__image {
    min-height: 30em;
    height: 60vh;
    display: flex;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transition: all 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.6)
      );
    }

    h1 {
      position: relative;
      font-size: clamp(2rem, 5vw, 4rem);
      font-weight: 700;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      letter-spacing: 0.05em;
      padding: 0 1rem;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.02);
      }
    }

    @media (max-width: 768px) {
      min-height: 25em;
      height: 50vh;
    }

    @media (max-width: 480px) {
      min-height: 20em;
      height: 40vh;
    }
  }
}

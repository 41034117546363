.langToggler {
  position: relative;

  &__select {
    appearance: none;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    color: $color-primary-yellow;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 0.8rem 3rem 0.8rem 1.2rem;
    transition: all 0.2s ease;

    // Custom dropdown arrow
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23deb992' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.8rem center;
    background-size: 1.6rem;

    // Remove default focus outline and add custom one
    &:focus {
      outline: none;
      border-color: rgba(255, 255, 255, 0.4);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    }

    // Style options
    option {
      background-color: #1a1a1a;
      color: #ffffff;
      padding: 1rem;
    }

    // Hover state
    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }

  // For dark mode/light mode adaptability
  @media (prefers-color-scheme: light) {
    &__select {
      option {
        background-color: #ffffff;
        color: #1a1a1a;
      }
    }
  }
}

.langToggler__text {
  font-weight: 400;
}

.dashboard {
   min-height: 83.5vh;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-size: cover;
   text-align: center;
   background-image: url("https://images.unsplash.com/photo-1563514227147-6d2ff665a6a0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80");

   // &__containerLeft {
   //    flex-basis:1;
   //    width: 20%;
   //    min-height: 83.5vh;
   //    height: 100%;
   //    background-color: $background-color;
   //    display: flex;
   //    justify-content: flex-start;
   //    align-items: center;
   //    flex-direction: column;
   //    opacity: 0.8;
   //    border-right: 1px solid $color-grey-dark-3;
   // }

   &__container {
      flex-basis: 2 ;
      width: 100%;
      min-height: 83.5vh;
      height: 100%;
      background-color: $background-color;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      opacity: 0.8;
      

      &Title {
         display: flex;  
         width: 100%;
         justify-content: center;         
         margin: 5rem 0;
         
         &Text {
            display: flex;  
            width: 80%;
            justify-content: center;
            margin-left: 2rem;

         }

         &Btn{
            position: absolute;
            top: 25vh;
            right: 2rem;
         }
      }


      &Text {
         margin: 5rem;
         text-align: justify;
         font-size: 1.6rem;
      }
   }
}
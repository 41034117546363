//=============================
//HOME JUMBO
//=============================

.homeJumbo {
  width: 100%;
  position: relative;
  margin: 80px 0 0;
  min-height: 60vh;
  contain: content; // Isolate the subtree
  content-visibility: auto; // Skip rendering off-screen content
  contain-intrinsic-size: 60vh; // Help browser with layout before content loads

  // Composite layer optimization
  transform: translate3d(0, 0, 0);
  will-change: transform;

  // Background optimization - use single variable
  background: rgba($color-primary-blue, 0.5) var(--bg-image) bottom/cover
    no-repeat;
  backface-visibility: hidden;

  @include query(max-w-600) {
    background: rgba($color-primary-blue, 0.5) var(--bg-image-mobile)
      center/cover no-repeat;
  }

  &__mapTitleContainer {
    position: absolute;
    inset: 50% auto auto 65%;
    width: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin-top: -50px;

    // Optimize container
    contain: layout style;
    will-change: transform;

    @include query(max-w-1024) {
      width: 70%;
    }

    @include query(max-w-600) {
      inset: 50% auto auto 50%;
      width: 75%;
      padding: 1rem;
      background-color: rgba($color-primary-blue, 0.7);
      border-radius: 10px;
      border: 2px solid $color-primary-blue;
    }
  }

  &__mapTitle {
    margin: 0 0 1.5rem;
    color: $color-primary-blue;
    font-size: clamp(2.5rem, 5vw, 4rem);
    font-weight: 700;
    letter-spacing: 0.1em;
    text-align: center;

    // Text rendering optimization
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include query(max-w-1024) {
      margin-bottom: 1rem;
    }

    @include query(max-w-600) {
      color: $color-primary-yellow;
    }
  }

  &__searchInput {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    padding-top: 1rem;
    contain: layout style;
  }

  &__button {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    border-radius: 6px;
    white-space: nowrap;
    transform: translateZ(0);
    will-change: transform;
    transition: transform 0.2s ease;

    &:hover {
      background-color: darken($color-primary-red-dark, 10%);
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(0);
    }

    @include query(max-w-600) {
      padding: 0.5rem 1rem;
      font-size: 1.1rem;
    }
  }

  // Skeleton styles
  &--skeleton {
    background: rgba($color-primary-blue, 0.1);
    animation: pulse 1.5s ease-in-out infinite;
  }

  &__mapTitle--skeleton {
    width: 80%;
    height: 4rem;
    background: rgba($color-primary-blue, 0.2);
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }

  &__button--skeleton {
    width: 200px;
    height: 48px;
    background: rgba($color-primary-blue, 0.2);
    border-radius: 6px;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}



//ARTICLE SHOW PARAGRAPH

.articleShowParagraph {
   margin: 1rem;
   padding: 1rem;
   width: 80%;
   margin: auto;

   @include query(max-w-1024) {
    width: 90%;
   }

   @include query(max-w-900) {
      width: 100%;
   }


   &__title {
      padding: 0 0 2rem;
      text-align: center;
      font-size: 1.3rem;
    }
    
    &__body {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: auto;
      padding: 1rem;

      @include query(max-w-1024) {
        width: 90%;
      }

      @include query(max-w-900) {
        width: 100%;
      }

      
    }
    
   
    
    &__image {
      padding: 1rem;
      margin: auto;
    }
    &__image img {
      max-width: 100%;
      height: auto;
    }

}
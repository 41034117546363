.legal-container {
  max-width: 800px;
  margin: 0 auto;
}

.legal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.legal-content h1 {
  font-size: 2rem;
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 1rem;
}

.legal-content ol {
  counter-reset: item;
  padding-left: 0;
}

.legal-content li {
  display: block;
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
}

.legal-content li:before {
  content: counter(item) ".";
  counter-increment: item;
  position: absolute;
  left: 0;
  color: #666;
  font-weight: bold;
}

.legal-content p {
  line-height: 1.6;
  color: #444;
}

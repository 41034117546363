//ARTICLE INDEX CARD

.articleIndexCard {
   flex-basis: 34%;
   display: flex;
   flex-direction: column;
   margin: 2rem;

   &__body {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1.5rem;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
      border-radius: 0% 0% 4% 4%;
    }
    
    &__img {
      max-width: 100%;
      height: auto;
      border-radius: 0.5% 0.5% 0% 0%;
    }
    
    &__title {
      text-align: center;
      font-size: 1.5rem;
    }
}

.articleIndexCardLg {
   flex-basis: 90%;
   display: flex;
   flex-direction: row;
   margin: 2rem;
   justify-content: space-around;

   &__date {
      padding-bottom: 0.5rem;
    }
    
   
    
    &__imgContainer {
      width: 45%;
      height: auto;
      padding: 1em;
      margin: auto;
    }
    
    &__imgContainer img {
      height: auto;
      max-width: 100%;
    }
    
    &__body {
      width: 60%;
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1.5rem;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
      border-radius: 0% 0% 4% 4%;
    }

    &__text {
      font-size: 2rem
    }

}
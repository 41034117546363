.addWineryNavbar {
   // position: fixed;
   min-height: 100%;
   width: 100%;
   padding: 3rem;
   background-color: $background-color-dark;



   &__list {
      list-style: none;
      transition: all 0.3s ease;
      font-size: 1.8rem;
      padding-bottom: 2rem;

      
      &:hover {
         cursor: pointer;
         color: $color-primary-redWine;
         text-decoration: underline;
      }
   }
   
}

.sidebarActive {
   color: $color-primary-redWine-light;
}
.wizardForm {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-around;
   width: 100%;

   
   &__breadCrums {
      padding-top: 5rem;
      position: fixed;
      top: 16%;
      background-color: #dfdfdf;
      display: flex;
      justify-content: center;
      z-index: 2;
      height: 8rem;
      
   }
   
   &__header {
     margin-bottom: 3rem;
   }


   &__formContainer {
      padding: 10rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
   }

   &__field {
      display: flex;
      width: 100%;
      padding: 1rem 5rem;

          
      input {
         font-size: 1.5rem;
         padding: .55em;
         width: 100%;
         margin-bottom: 1em;
         border: none;
         background-color: $background-color-dark;
         border-bottom: 1px solid rgba($color-primary-redWine, 0.8);
         outline: none;
         box-shadow: none;
         color: #444;
         appearance: textfield;
      }

      &--grape {
         width: 100%;

         &--btn {
            button {
               font-size: 1.2rem;
               margin: 1rem;

            }
         }
      }
   }

   &--active input {
      border-bottom: 1px solid rgba($color-primary-redWine-light, 0.8);     
      color:#000;    
   }

   &__error {
      width: 10%;
      color:$color-primary-redWine;
   }


   &__schedule {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      margin-bottom: 5rem;
      
      &-Field{ 
       display: flex;
       flex-direction: column;
       width: 20%;
       padding: 1rem 5rem;
       align-items: space-between;
       justify-content: center;
       font-size: 1.8rem;

       input[type=time] {
         border: 1px solid $color-primary-redWine;
         border-radius: 0.5rem;
         color: $color-primary-redWine;
         font-size: 1.4rem;
         font-family: helvetica;
         width: 12rem;
         margin: .5rem;
       }

       div{
          margin: 1rem 1rem;
       }
    
       input {      
          box-shadow: none;
              
          }
      }
  }

   
   &__amenitiesContainer {
      width: 100%;

   }

   &__amenities-field {
      font-size: 1.6rem;
      width: 100%;
      padding: 1rem 5rem 1rem 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-textInput {
         width: 80%;
      }

       &-title{
          display: flex;
          justify-content: flex-start;
          align-items: center;
            width: 20%;

            lable {
             padding: .5rem;
            }
            icon{
               padding: 1.2rem;
            }
  
       }
       
    
       input {      
         font-size: 1.5rem;
         padding: .55em;
         width: 95%;
         margin-bottom: 1em;
         border: none;
         background-color: $background-color-dark;
         border-bottom: 1px solid rgba($color-primary-redWine, 0.8);
         outline: none;
         box-shadow: none;
         color: #444;
         appearance: textfield;              
          }
   }

}


.wineryShowJumbo {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 80px;

  &__imageLogo {
    width: 120px;
    height: 80px;
    margin-left: auto;
    border-radius: 8px;
    z-index: 1;
    transition: transform 0.3s ease;
    color: white;
    opacity: 0.87;
    padding: 10px;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__nameContainer {
    display: flex;
    margin: auto auto 0 auto;
    align-items: center;
    height: 35%;
    width: 95%;
    max-width: 1300px;
    z-index: 2;
  }

  &__image {
    min-height: 30em;
    height: 60vh;
    display: flex;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transition: all 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.4) 20%,
        rgba(0, 0, 0, 0) 70%
      );
    }

    h1 {
      font-size: clamp(2rem, 5vw, 4rem);
      font-weight: 700;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      letter-spacing: 0.05em;
      padding: 0 1rem;
      margin: 0;
      width: 75%;
    }

    @media (max-width: 768px) {
      min-height: 25em;
      height: 50vh;
    }

    @media (max-width: 480px) {
      min-height: 20em;
      height: 40vh;
    }
  }
}
